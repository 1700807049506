import api from '../../utils/api'

// 授权
export async function diagnosis_info (payload) {
  return api.get(`/api/v1/mp/reservation/diagnosis`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/reservation/diagnosis`,payload)
}

export async function family_info (payload) {
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}

export async function set_default_relation (payload) {
  return api.post(`/api/v1/mp/families/relationships/defaults`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships/defaults`,payload)
}

export async function get_order_id (payload) {
  return api.post(`/api/v1/mp/orders/`, payload)
}
