<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="header">
        <div
          class="header_item"
          :class="type_index === 0 ? 'active' : ''"
          @click="title_click(0)"
        >
          全部
        </div>
        <div
          class="header_item"
          :class="type_index === 1 ? 'active' : ''"
          @click="title_click(1)"
        >
          按疾病搜索
        </div>
      </div>
      <div class="main_out">
        <div class="serach_box" v-if="type_index === 0">
          <div class="serch_out">
            <van-search
              shape="round"
              v-model="value"
              placeholder="搜索检验检查如血常规、胸片等"
            />
          </div>
          <div class="main">
            <div class="title_box">
              <div
                class="title"
                :class="check_index === 0 ? 'active' : ''"
                @click="check_index = 0"
              >
                <p>检查</p>
                <div class="badge" v-if="exam_num">{{ exam_num }}</div>
              </div>
              <div
                class="title"
                :class="check_index === 1 ? 'active' : ''"
                @click="check_index = 1"
              >
                <p>检验</p>
                <div class="badge" v-if="lab_num">{{ lab_num }}</div>
              </div>
            </div>
            <div class="symptoms_box" v-if="s_data && s_data.all">
              <div class="lab_box" v-if="check_index === 0">
                <div class="lab_title">检查项目</div>
                <div
                  class="lab_items_box"
                  v-if="s_data.all.examinations.length"
                >
                  <div
                    class="lab_items"
                    :class="item.exist ? 'active' : ''"
                    v-for="(item, idx) in s_data.all.examinations"
                    :key="idx"
                    @click="select_sub(item)"
                  >
                    <div class="lab_icon"></div>
                    <div class="text_box">
                      <p class="lab_items_title">
                        <span v-html="text_trim(item.name)"></span>
                        <span class="lab_items_type" v-if="item.specimen">{{
                          item.specimen
                        }}</span>
                      </p>
                      <p
                        class="lab_items_text"
                        v-html="format_text(item.introduction)"
                      ></p>
                    </div>
                    <div class="price_box">¥{{ format_money(item.price) }}</div>
                  </div>
                </div>
                <div v-else>
                  <van-empty
                    image="search"
                    description="未找到您所查询的检查项目"
                  />
                </div>
              </div>

              <div class="lab_box" v-if="check_index === 1">
                <div class="lab_title">检验项目</div>
                <div
                  class="lab_items_box"
                  v-if="s_data.all.laboratories.length"
                >
                  <div
                    class="lab_items"
                    :class="item.exist ? 'active' : ''"
                    v-for="(item, idx) in s_data.all.laboratories"
                    :key="idx"
                    @click="select_sub(item)"
                  >
                    <div class="lab_icon"></div>
                    <div class="text_box">
                      <p class="lab_items_title">
                        <span v-html="text_trim(item.name)"></span>
                        <span class="lab_items_type" v-if="item.specimen">{{
                          item.specimen
                        }}</span>
                      </p>
                      <p
                        class="lab_items_text"
                        v-html="format_text(item.introduction)"
                      ></p>
                    </div>
                    <div class="price_box">¥{{ format_money(item.price) }}</div>
                  </div>
                </div>
                <div v-else>
                  <van-empty
                    image="search"
                    description="未找到您所查询的检验项目"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="type_box" v-if="type_index === 1">
          <div class="main">
            <div class="title_box" ref="title_out">
              <div
                class="title"
                ref="title_item"
                :class="idx === index ? 'active' : ''"
                v-for="(item, idx) in data.diagnosis"
                :key="idx"
                @click="select_ill(item, idx)"
                v-show="item.examinations.length || item.laboratories.length"
              >
                <p>{{ item.standard_name }}</p>
                <div
                  class="badge"
                  :class="num.toString().length > 1 ? 'small' : ''"
                  v-if="item.count"
                >
                  {{ item.count }}
                </div>
              </div>
            </div>
            <div class="symptoms_box">
              <div
                class="lab_box"
                v-if="sub.examinations && sub.examinations.length"
              >
                <div class="lab_title">检查项目</div>
                <div class="lab_items_box">
                  <div
                    class="lab_items"
                    :class="item.exist ? 'active' : ''"
                    v-for="(item, idx) in sub.examinations"
                    :key="idx"
                    @click="select_sub(item)"
                  >
                    <div class="lab_icon"></div>
                    <div class="text_box">
                      <p class="lab_items_title">
                        <span v-html="text_trim(item.name)"></span>
                        <span class="lab_items_type" v-if="item.type">{{
                          item.type
                        }}</span>
                      </p>
                      <p
                        class="lab_items_text"
                        v-html="format_text(item.introduction)"
                      ></p>
                    </div>
                    <div class="price_box">¥{{ format_money(item.price) }}</div>
                  </div>
                </div>
              </div>

              <div
                class="lab_box"
                v-if="sub.laboratories && sub.laboratories.length"
              >
                <div class="lab_title">检验项目</div>
                <div class="lab_items_box">
                  <div
                    class="lab_items"
                    :class="item.exist ? 'active' : ''"
                    v-for="(item, idx) in sub.laboratories"
                    :key="idx"
                    @click="select_sub(item)"
                  >
                    <div class="lab_icon"></div>
                    <div class="text_box">
                      <p class="lab_items_title">
                        <span v-html="text_trim(item.name)"></span>
                        <span class="lab_items_type" v-if="item.specimen">{{
                          item.specimen
                        }}</span>
                      </p>
                      <p
                        class="lab_items_text"
                        v-html="format_text(item.introduction)"
                      ></p>
                    </div>
                    <div class="price_box">¥{{ format_money(item.price) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom_box">
        <div class="user_box" v-if="show_user">
          <p v-if="family && family.members && family.members.length">
            您当前就诊人为
            <span>{{
              family.members.filter((item) => {
                return item.default_member;
              })[0].name
            }}</span>
            可以
            <span class="switch_btn" @click="showFamily = true"
              >切换就诊人</span
            >
          </p>
          <div class="close_user" @click="show_user = false">X</div>
        </div>
        <div class="pay_box">
          <div class="img_box">
            <img src="../../assets/img/pay_head.png" alt="" width="43" />
            <div
              class="badge"
              :class="num.toString().length > 1 ? 'small' : ''"
            >
              {{ num }}
            </div>
          </div>
          <div class="price_items">
            <div class="price_new">
              <p>¥ {{ format_money(price) }}</p>
            </div>
            <p class="exam_items">
              已选 <span>{{ num }}</span> 项检验检查
            </p>
          </div>
          <van-button
            :color="num ? '#0088FF' : '#cccccc'"
            type="primary"
            class="pay_btn"
            :disabled="!num"
            @click="confirm_click"
            >结算</van-button
          >
        </div>
      </div>

      <van-popup
        v-model="showFamily"
        round
        position="bottom"
        :style="{ minHeight: '15%', maxHeight: '80%' }"
      >
        <div
          class="family_box"
          v-if="family && family.members && family.members.length"
        >
          <div class="family_title">切换家人</div>
          <div
            class="family_card"
            v-for="(item, idx) in family.members.filter((item) => {
              return !item.default_member;
            })"
            :key="idx"
          >
            <div class="family_head">
              <div class="img_out">
                <img
                  :src="get_user_head(item.relationship, item.gender)"
                  alt=""
                  width="41"
                />
              </div>
              <div>{{ item.relationship_label }}</div>
            </div>

            <div class="family_info">
              <p class="family_name">{{ item.name }}</p>
              <p>健康卡: {{ format_medical_card(item.medical_card_no) }}</p>
            </div>
            <div class="family_btn" @click="chooseFamily(item)">选择</div>
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="show_situation"
        position="bottom"
        :close-on-click-overlay="false"
        round
        :style="{ minHeight: '20%' }"
      >
        <div class="situation_box">
          <div class="situation_title">
            {{ un_suitabel.name }}检查不适宜人群告知
          </div>
          <div class="situation_sub">请明确您不存在如下情况</div>
          <div class="situation_list" v-html="un_suitabel.des"></div>
          <div class="situation_bottom">
            <div class="situation_btn" @click="send_situation(true)">
              我不存在以上情况
            </div>
            <div class="situation_btn" @click="send_situation(false)">
              我有以上某项情况
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  diagnosis_info,
  family_info,
  set_default_relation,
  get_order_id,
} from "./service";
import { format_money, format_medical_card } from "../../utils/format";
import { get_user_head } from "../../utils/imgUrl";

export default {
  data() {
    return {
      loading: false,
      data: {},
      s_data: {},
      index: 0,
      sub: {},
      check_rs: [],
      num: 0,
      price: 0,
      show_user: true,
      showFamily: false,
      family: [],
      type_index: 0,
      value: "",
      check_index: this.$route.query.mode === "labs" ? 1 : 0,
      exam_num: 0,
      lab_num: 0,
      timer: null,
      delay: 500,
      exam_results: [],
      lab_results: [],
      medical_price: 0,
      self_price: 0,
      reg: /^([^_]+)_([^_]+)$/,
      show_situation: false,
      un_suitabel: {
        name: "",
        des: "",
      },
      selected_item: null,
    };
  },
  computed: {},
  components: {},
  watch: {
    value(new_value, old_value) {
      if (new_value !== old_value) {
        this.loading = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(this.input, this.delay);
      }
    },
  },

  created() {
    this.get_member();
    this.get_diagnosis();
  },
  methods: {
    text_trim(text) {
      if (!text) {
        return;
      }
      return text.replace(/^\s+|\s+$/gm, "");
    },
    format_text(text) {
      if (!text) {
        return;
      }
      return text.replace(/<\/?.+?\/?>/g, "");
    },
    format_money,
    format_medical_card,
    get_user_head,
    title_click(idx) {
      this.type_index = idx;
    },
    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    lite_unique(arr) {
      return [...new Set(arr)];
    },
    async confirm_click() {
      let payload = {
        examine_ids: [],
        laboratory_ids: [],
      };
      if (this.lab_results.length) {
        payload.laboratory_ids = this.lite_unique(
          this.lab_results.map((item) => item.id)
        );
      }
      if (this.exam_results.length) {
        payload.examine_ids = this.lite_unique(
          this.exam_results.map((item) => item.id)
        );
      }
      this.loading = true;
      try {
        const { data } = await get_order_id(payload);
        this.$router.push({
          path: "pre-order",
          query: {
            order_id: data.order_id,
            length: payload.examine_ids.length + payload.laboratory_ids.length,
            anesthesia_estimate: data.anesthesia_estimate||false,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    input() {
      this.s_data.all.laboratories = [];
      this.s_data.all.examinations = [];
      if (this.value && this.value.length) {
        this.s_data.all_data.forEach((item) => {
          if (item.name && item.name.includes(this.value.toUpperCase())) {
            let obj =
              item.type !== 1 && item.type !== 2
                ? this.s_data.all.examinations
                : this.s_data.all.laboratories;
            obj.push(item);
          }
        });
        this.check_index = !this.s_data.all.examinations.length ? 1 : 0;
      } else {
        this.s_data.all.laboratories = this.s_data.all_data.filter((item) => {
          return item.type === 1 || item.type === 2;
        });
        this.s_data.all.examinations = this.s_data.all_data.filter((item) => {
          return item.type !== 1 && item.type !== 2;
        });

        this.check_index = 0;
      }
      this.loading = false;
    },
    send_situation(v) {
      if (v) {
        this.inner_sub(this.selected_item);
      }
      this.$nextTick(() => {
        this.show_situation = false;
      });
    },
    select_regular(item) {
      item.exist = !item.exist;
      let results =
        item.type !== 1 && item.type !== 2
          ? this.exam_results
          : this.lab_results;
      if (item.exist) {
        results.push(item);
        if (this.type_index) {
          //按疾病选 选中
          this.data.diagnosis[this.index].count++;
        } else {
          //全部
          item.type !== 1 && item.type !== 2 ? this.exam_num++ : this.lab_num++;
        }
      } else {
        results.splice(results.indexOf(item), 1);
        if (this.type_index) {
          //按疾病 取消选中
          this.data.diagnosis[this.index].count--;
        } else {
          //全部
          item.type !== 1 && item.type !== 2 ? this.exam_num-- : this.lab_num--;
        }
      }
    },
    // inner_sub(item) {
    //   console.log(item.type)
    //   item.exist = !item.exist;
    //   let results = (item.type !==1 && item.type !== 2) ? this.exam_results : this.lab_results;
    //   if(item.exist) {
    //     results.push(item);
    //     if(this.type_index) {
    //       //按疾病选 选中
    //       this.data.diagnosis[this.index].count++;
    //     }else {
    //       //全部
    //       (item.type !==1 && item.type !== 2) ? this.exam_num++ : this.lab_num++;
    //     }
    //   } else {
    //     results.splice(results.indexOf(item), 1);
    //     if(this.type_index) {
    //       //按疾病 取消选中
    //       this.data.diagnosis[this.index].count--;
    //     }else {
    //       //全部
    //       (item.type !==1 && item.type !== 2) ? this.exam_num-- : this.lab_num--;
    //     }
    //   }
    // },
    inner_sub(item) {
      if(item.name === "无痛胃镜" || item.name === "无痛肠镜") {
        this.$dialog.confirm({
          title: '提示',
          message: `${item.name}不支持与其他检查一起预约，如需继续预约会取消其他已选检查检验项目，是否继续？`,
          confirmButtonText: '是',
          cancelButtonText: '否',
        })
          .then(() => {
            this.$router.push({
              path: '/colonoscopy',
              query: {
                id: item.id
              }
            })
          })
          .catch(() => {
            
          })
      } else {
        this.select_regular(item)
        this.set_sub(this.index);
        this.calc_data(item);  
      }
    },
    select_sub(item) {
      // 设置选中项目optional为false
      item.optional = false;
      this.selected_item = item;
      if (item.un_suitable && !item.exist) {
        this.un_suitabel.name = item.standard_name;
        this.un_suitabel.des = item.un_suitable.split("；").join("<br>");
        this.show_situation = true;
      } else {
        this.inner_sub(item);
      }
    },
    select_ill(item, idx) {
      this.index = idx;
      this.set_sub(idx);
    },
    async get_diagnosis() {
      this.loading = true;
      try {
        const { data } = await diagnosis_info();
        this.set_data(data);
        this.set_sub(this.index);
      } finally {
        this.loading = false;
      }
    },
    set_sub(idx) {
      this.sub = this.data.diagnosis[idx];
    },
    unique(arr, key) {
      const map = new Map();
      return arr.filter(
        (item) => !map.has(item[key] + "") && map.set(item[key] + "", 1)
      );
    },
    set_data(data) {
      data.all_data = [];
      data.diagnosis.forEach((el, idx) => {
        if (el.exist === undefined) {
          el.exist = false;
        }
        let count = 0;
        let arr = el.laboratories || el.examinations;
        arr.forEach((item) => {
          item.father_index = idx;
        });
        el.count = count;
      });
      data.all_data = [...data.all.laboratories, ...data.all.examinations];
      this.s_data = data;
      this.data = this.clone(data);
    },
    calc_data(item) {
      if (item.exist) {
        this.num++;
        this.price += item.price;
      } else {
        this.num--;
        this.price -= item.price;
      }
    },
    async get_member() {
      this.loading = true;
      try {
        const { data } = await family_info();
        this.family = data;
      } finally {
        this.loading = false;
      }
    },
    async chooseFamily(item) {
      this.loading = true;
      try {
        await set_default_relation({
          medical_card_no: item.medical_card_no,
        });
        this.get_member();
        this.showFamily = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.g_main_content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main_out {
  flex: 1;
  overflow: hidden;
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  color: #565656;
  border-bottom: solid 10px rgba(112, 112, 112, 0.06);
}

.header_item {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  position: relative;

  &.active {
    font-weight: 500;

    &::after {
      content: "";
      width: 50px;
      height: 3px;
      background: #0088ff;
      position: absolute;
      bottom: 0;
    }
  }
}

.hot_box {
  padding: 12px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.06);
  width: 100%;
  overflow-x: auto;
}

.hot_box_out {
  display: -webkit-box;
}

.hot_items {
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #565656;
  text-align: center;

  & p {
    margin-top: 5px;
  }
}

.splite_line {
  height: 1px;
  background: 1px solid #707070;
  opacity: 0.16;
}

.main {
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.title_box {
  width: 34%;
  height: 100%;
  overflow-y: auto;
}

.serach_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  & .title_box {
    width: 25%;
    height: 100%;
    background: #f7f7f7;
  }

  & .symptoms_box {
    width: 75%;
    height: 100%;
    /* animation: fade_in .6s ease-in-out; */
  }
}

.title {
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #565656;
  position: relative;
  background: #f7f7f7;
  justify-content: center;
  text-align: center;
  line-height: 18px;

  & p {
    /* margin-left: 2px; */
    font-size: 14px;
    max-height: 36px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -o-text-overflow: ellipsis;
    table-layout: fixed;
  }

  &.active {
    background: #ffffff;
    font-weight: 500;
  }
}

.badge {
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  background: #ff7823;
  border-radius: 50%;
  opacity: 1;
  color: #ffffff;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;

  &.small {
    font-size: 10px;
  }
}

.symptoms_box {
  width: 66%;
  background: #ffffff;
  overflow-y: auto;
}

@keyframes fade_in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.fade-enter{
  opacity: 0;
}
.fade-enter-active{
  transition: opacity 3s;
}
.fade-leave-to{
  opacity: 0;
}
.fade-leave-active{
  transition: opacity 3s;
}

.lab_title {
  padding-left: 16px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 500;
  color: #565656;
}

.lab_items_box {
  padding: 0 22px 0 18px;
}

.lab_items {
  padding: 20px 0 17px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.06);
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  color: #565656;

  &.active .lab_icon {
    background: url("../../assets/img/check-box-active.png") no-repeat center;
    background-size: cover;
    border: none;
  }
}

.lab_icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  border-radius: 50%;
  border: solid 1px #0088ff;
}

.text_box {
  flex: 1;
}

.lab_items_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  padding-right: 5px;
  display: flex;
}

.lab_items_type {
  font-size: 12px;
  font-weight: 400;
  margin-left: 3px;
  flex: 1;
}

.lab_items_text {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  width: 95%;
  max-height: 32px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  table-layout: fixed;
}
.price_box {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #0088ff;
}

.pay_box {
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
}

.img_box {
  position: relative;

  & .badge {
    top: 2px;
    right: -2px;
  }
}

.price_items {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #242938;
  padding-left: 20px;
  width: 60%;
}

.exam_items {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #454545;

  & span {
    color: #0088ff;
  }
}
.pay_btn {
  width: 100px;
  height: 32px;
  opacity: 1;
  border-radius: 21px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.price_new {
  display: flex;
}

.user_box {
  background: #fefced;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  color: #565656;
  justify-content: space-between;

  & span {
    font-weight: 500;
    flex: 1;
  }
}

.switch_btn {
  color: #008fff;
  font-weight: 400;
}

.close_user {
  margin-left: 14px;
}

.family_box {
  padding: 25px;
}

.family_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #242938;
}

.family_card {
  margin-top: 24px;
  padding-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.family_head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #ffffff;
  margin-right: 20px;

  & div {
    width: 43px;
    height: 17px;
    background: #ffa700;
    opacity: 1;
    border-radius: 9px;
    text-align: center;
    margin-top: -2px;
  }

  & .img_out {
    width: 45px;
    height: 45px;
    background: #edf5ff;
    border: 2px solid #ffffff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 28px;
    text-align: center;
    margin-top: 0;
  }
}

.family_info {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #707070;
  width: 150px;
}

.family_name {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: #242938;
}

.family_btn {
  width: 66px;
  height: 30px;
  background: #ff7823;
  box-shadow: 0px 2px 8px rgba(255, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}

.situation_box {
  padding: 24px;
}

.situation_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #565656;
}

.situation_sub {
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  color: #565656;
  margin-bottom: 14px;
  margin-top: 4px;
}

.situation_list {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
  margin-bottom: 23px;
}

.situation_bottom {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.situation_btn {
  width: calc((100% - 12px) / 2);
  height: 40px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 40px;
  color: #0088ff;
  text-align: center;
  border: 1px solid #eaeaea;
}

.type_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
</style>
